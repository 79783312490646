import tw from 'twin.macro';
import SEO from '../components/seo';
import ServiceStatus from '../components/status';
import LandingLayout from './landing';

interface CustomLayoutProps {
  title?: string;
  children?: React.ReactNode;
}

const Container = tw.div`mx-auto px-8 py-8 max-w-screen-lg`;

const CustomLayout: React.FC<CustomLayoutProps> = ({ title, children }) => (
  <LandingLayout>
    <SEO title={title} />
    <Container>{children}</Container>
  </LandingLayout>
);

export default CustomLayout;
