import React from 'react';
import tw from 'twin.macro';
import CustomLayout from '../layouts/custom';

const Container = tw.div`p-5 bg-gradient-to-r from-indigo-100 to-purple-50 text-gray-600 text-lg font-light`;
const Title = tw.div`mb-2 text-black text-xl font-bold`;
const Qr = tw.img`mx-auto mt-6 w-60 h-80 bg-white shadow-lg`;
const Caption = tw.div`mt-4 mb-4 text-gray-500 text-sm text-center`;
const Center = tw.div`text-center`;
const CTA = tw.a`mx-0.5 text-purple-600 font-semibold hover:underline`;

export default () => (
  <CustomLayout title="在线咨询">
    <Container>
      <Title>在线咨询</Title>
      <span role="img" aria-label="快速响应">
        ⚡️
      </span>
      <span>&nbsp;</span>
      <span>在线解答产品方案、技术架构和开发成本核算的疑问。</span>
    </Container>
    <Qr alt="微信号" src="/wechat_qr.jpg" />
    <Caption>扫码免费咨询</Caption>
    <Center>
      或者发送邮件到<CTA href="mailto:lhzbxx@gmail.com?subject=提交需求">邮箱</CTA>；
      如果需要我们主动联系您，请
      <CTA href="https://wj.qq.com/s2/9517779/bd18/" target="_blank" rel="noreferrer">
        提交需求
      </CTA>
      给我们。
    </Center>
  </CustomLayout>
);
